var CustomService = function () {

    function selectMenu(url) {

        var navItem = $('.page-sidebar-menu a[href="'+ url + '"].nav-link:not(.nav-toggle)');
        if (navItem.length > 0) {
            while (!navItem.hasClass('page-sidebar-menu')) {

                if (navItem.hasClass('nav-item')) {
                    navItem.addClass('active open');
                }

                navItem = navItem.parent();
            }

            navItem.children('.nav-item.active > .nav-item');
        }

        $('.page-sidebar-menu > .nav-item.active > .nav-link').append('<span class="selected"></span>')
    }

    function init() {
        selectMenu(window.location.pathname);
    }

    return {
        init: init,
        selectMenu: selectMenu
    }
}();

$(CustomService.init);